<template>
  <v-card>
    <v-card-text>
      ?Need analyse?

      Other work items (key reasons)
    </v-card-text>

  </v-card>
</template>
<script>
export default {
  props: {
  }
}
</script>
<style lang="stylus" scoped></style>
